/** @jsxImportSource preact */
import FontAwesomeIcon from '$/components/FontAwesomeIcon'
import SiteDialog from '$/components/SiteDialog'
import { COUNTRIES, STATES } from '$/form-config'
import { Data, setShow } from '$/store/personal-information'
import { personalInformation, reset, submitForm, updateValue } from '$/store/personal-information'
import { useStore } from '@nanostores/preact'
import { useMemo, useState } from 'preact/hooks'

function FormRadio(props) {
  const id = 'input-' + props.label.replace(/\s/g, '-').toLowerCase()
  return (
    <>
      <div className="form-check u-mt-2">
        <input
          type="radio"
          name={props.name}
          id={id}
          value={props.label}
          onChange={(e: any) => updateValue(props.name, e.target.value)}
        />
        <label htmlFor={id} className="u-ml-1 u-block u-text-sm">
          {props.label}
        </label>
      </div>
    </>
  )
}

type FormProps = {
  name: keyof Data
  type?: string
  label: string
  options?: {
    [k: string]: string
  }
  value?: string
  autocomplete: string
}

function FormSelect(props: FormProps) {
  const { errors } = useStore(personalInformation)
  const id = 'input-' + props.name
  const error = errors?.[props.name]

  return (
    <>
      <div className="u-mt-2 u-flex u-flex-col">
        <label htmlFor={id} className="u-block u-text-sm u-font-semibold u-uppercase">
          {props.label}
          <span aria-hidden="true">*</span> <span className="u-sr-only">(required)</span>
        </label>
        <select
          name={props.name}
          onChange={(e: any) => updateValue(props.name, e.target.value)}
          className="form-select u-mt-1 u-block u-w-full u-border u-p-1 focus:u-border-grey focus:u-outline-none"
          value={props.value}
        >
          <option value="">Select one...</option>
          {Object.entries(props.options).map(([key, value]) => (
            <option name={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>
      {error && <div class="form-error">{error[0].message}</div>}
    </>
  )
}

function FormInput(props: FormProps) {
  const { errors } = useStore(personalInformation)
  const id = 'input-' + props.name
  const type = props.type || 'text'
  const error = errors?.[props.name]

  return (
    <>
      <div className="u-mt-2 u-flex u-flex-col">
        <label htmlFor={id} className="u-block u-text-sm u-font-semibold u-uppercase">
          {props.label}
          <span aria-hidden="true">*</span> <span className="u-sr-only">(required)</span>
        </label>
        <input
          type={type}
          name={props.name}
          id={id}
          class="u-mt-1 u-block u-border u-p-1 focus:u-border-grey focus:u-outline-none"
          autocomplete={props.autocomplete}
          aria-invalid={error}
          value={props.value}
          onChange={(e: any) => updateValue(props.name, e.target.value)}
        />
      </div>
      {error && <div class="form-error">{error[0].message}</div>}
    </>
  )
}

function ModalPersonalInformation({ close }: { close: () => void }) {
  const {
    status,
    errors,
    data: { countryCode },
  } = useStore(personalInformation)
  const subjectError = errors?.['subject']

  const subjectFieldset = [
    { name: 'subject', label: 'Info Request' },
    { name: 'subject', label: 'Data Deletion' },
    { name: 'subject', label: 'Do Not Sell My Personal Information' },
  ]

  const closeModal = () => {
    reset()
    close()
  }

  const customerFieldset = useMemo<FormProps[]>(() => {
    const states = STATES[countryCode]
    return [
      { name: 'email', label: 'Email Address', type: 'email', autocomplete: 'email' },
      { name: 'firstName', label: 'First Name', autocomplete: 'given-name' },
      { name: 'lastName', label: 'Last Name', autocomplete: 'family-name' },
      {
        name: 'countryCode',
        label: 'Country',
        type: 'select',
        options: COUNTRIES,
        value: countryCode,
        autocomplete: 'country',
      },
      { name: 'address', label: 'Address', autocomplete: 'street-address' },
      { name: 'city', label: 'City', autocomplete: 'address-level2' },
      {
        name: 'stateProvince',
        label: 'State / Province',
        type: 'select',
        options: states,
        autocomplete: 'address-level1',
      },
      { name: 'postalCode', label: 'Postal Code', autocomplete: 'postal-code' },
    ]
  }, [countryCode])

  return (
    <SiteDialog close={closeModal} class="u-max-w-xl lg:u-max-h-[90vh]">
      <div className="l-stack">
        <div class="l-stack u-text-sm">
          <h1 class="legacy-h3 u-text-center">
            Don't Sell My
            <br />
            Personal Information
          </h1>
          <p>
            To opt out of sales, please provide information on the CCPA Sale Opt Out Form below, which will help us
            locate your records, and press submit. We will complete your request as quickly as possible.
          </p>
        </div>
        {status !== 'success' && (
          <form onSubmit={(e) => submitForm(e, closeModal)} className="u-mt-4">
            <fieldset>
              <legend className="u-text-sm u-font-semibold u-uppercase">Request Type</legend>
              {subjectFieldset.map((input) => (
                <FormRadio {...input} />
              ))}
              {subjectError && <div className="form-error">{subjectError[0].message}</div>}
            </fieldset>
            <fieldset className="u-mt-4">
              {customerFieldset.map((input) => {
                return input.type === 'select' && input.options ? <FormSelect {...input} /> : <FormInput {...input} />
              })}
            </fieldset>
            <div className="u-mt-4">
              <button className="button button--block" aria-disabled={status === 'loading'}>
                {status !== 'loading' ? (
                  'Submit'
                ) : (
                  <FontAwesomeIcon pack="regular" icon="spinner-third" class="fa-fw" />
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </SiteDialog>
  )
}

export default function PersonalInformation(props: any) {
  const { show } = useStore(personalInformation)

  return (
    <>
      <button type="button" onClick={() => setShow(true)} {...props}>
        Don't Sell My Personal Information
      </button>
      {show && <ModalPersonalInformation close={() => setShow(false)} />}
    </>
  )
}
